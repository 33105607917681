<template>
  <div v-if="!isLoading">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="font-weight-bolder text-primary">
          Delivery Tracking
        </h1>
      </div>
      <div class="bg-white d-flex align-items-center">
        <div class="bg-primary py-1 px-3 text-center">
          <p class="font-weight-bolder m-0 p-0 h5 text-white">
            Delivery ID
          </p>
        </div>
        <div class="py-1 px-3">
          <p class="font-weight-bolder m-0 p-0 h5 text-success">
            {{ deliveries.id }}
          </p>
        </div>
      </div>
    </div>
    <app-collapse
      :accordion="true"
      type="margin"
      class="mb-1 mt-3"
    >
      <app-collapse-item
        v-for="(order, index) in deliveries.orders"
        :key="index"
        title=""
      >
        <template #header>
          <h4 class="text-primary mb-0">
            {{ `Order ID: ${order.reference_id}` }}
          </h4>
        </template>
        <OrderTrackingDetails
          :order="order"
          :is-loading="isLoading"
        />
      </app-collapse-item>
    </app-collapse>

    <b-container class="text-center">
      <b-button
        type="button"
        variant="primary"
        class="mt-2"
        to="/tracking"
      >Go Back To Tracking</b-button>
    </b-container>
  </div>
</template>
<script>
import OrderTrackingDetails from '@/components/OrderTrackingDetails.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'

export default {
  components: {
    OrderTrackingDetails,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    ...mapState({
      deliveries: state => state.trackAndTrace.deliveries,
      isLoading: state => state.trackAndTrace.isLoading,
    }),
  },
  async created() {
    if (!this.delivery) {
      const response = await this.$store.dispatch('trackAndTrace/fetchDeliveries', { deliveryId: this.$route.params.id })
      if (response.status === 404) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Not Exist',
            text: 'Delivery or Order ID is not exist',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.$router.replace('/tracking')
      }
    }
  },
}
</script>
